<template>
  <v-dialog v-model="dialog" persistent max-width="25rem">
    <v-card>
      <v-card-title>{{$vuetify.lang.t('$vuetify.codeTip5')}}</v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-text-field
            :label="$vuetify.lang.t('$vuetify.oldPassword')"
            :rules="[rule.required]"
            v-model="passwordForm.pass"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
          />
          <v-text-field
            :label="$vuetify.lang.t('$vuetify.newPassword')"
            :rules="[rule.required, rule.passwordLength]"
            v-model="passwordForm.new_pass"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
          />
          <v-text-field
            :label="$vuetify.lang.t('$vuetify.confirmPass')"
            :rules="[rule.required, rule.notMatch]"
            v-model="passwordForm.password"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            @click:append="show3 = !show3"
          />
          
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="resetForm"> {{$vuetify.lang.t('$vuetify.shut')}} </v-btn>
        <v-btn @click="validate"> {{$vuetify.lang.t('$vuetify.Confirm')}} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      show1: false,
      show2: false,
      show3: false,
      rule: {
        required: (v) => !!v || this.$vuetify.lang.t('$vuetify.fieldRequired'),
        passwordLength: (v) => v.length >= 6 ? true : this.$vuetify.lang.t('$vuetify.passwordCannotLess'),
        notMatch: (v) => v === this.passwordForm.new_pass ? true : this.$vuetify.lang.t('$vuetify.passwordNotmatch')
      },
    };
  },
  props: {
    passwordForm: Object,
    dialog: Boolean,
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("handleData", this.passwordForm);
        this.resetForm()
      }
    },
    resetForm() {
      this.$refs.form.resetValidation();
      
      this.$emit("update:dialog", false);
    },
  },
};
</script>

<style>
</style>